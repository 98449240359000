//$body-bg: #efefef;
$color: #999;
//$title-font:"Robotto", sans-serif;
//$base-font:"Lato", sans-serif;
$theme-colors: (
  'primary': #FE7820,
  'dark': #4B5161
);
$primary-color:#FE7820;
$danger-color:#c9302c;
$success-color:#5cb85c;
$warning-color:#F58B44;
$default-color:#f7f7f7;
$dark-color:#4B5161;
$info-color:#52B6DE;

// Import Bootstrap and its default variables
//@import "../node_modules/bootstrap/scss/bootstrap";
//@import "../node_modules/rc-slider/assets/index.css";

// Laapp theme
@import "./laapp/laapp";

/*
Project - Classic landing onepage template
*/
/*@font-face {
  font-family: 'FontAwesome';
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format('embedded-opentype'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0") format('woff2'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0") format('woff'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format('truetype'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format('svg');
  font-weight: normal;
  font-style: normal;
}*/
//@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700');
//@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700');
@import "styles/_variables.scss";
//@import "styles/_base.scss";
//@import "styles/_buttons.scss";
//@import "styles/_preloader.scss";
//@import "styles/_navbar.scss";
//@import "styles/_hero.scss";
//@import "styles/_icon-card.scss";
//@import "styles/_split-image.scss";
//@import "styles/_pricing-boxes.scss";
//@import "styles/_dropdown.scss";
//@import "styles/_testimonial.scss";
//@import "styles/_footer.scss";
@import "styles/_plant.scss";

/** responsive style**/
@media(max-width:991px){
  .hero-content .display-3{
    font-size:3rem;
  }
  .navbar.navbar-light.navbar-transparent.bg-faded,.navbar.navbar-light.navbar-transparent.bg-faded.fixed-top{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:999;
    background-color:#fff!important;
    padding: .7rem 1rem;
    border-bottom:1px solid #eee;
    .navbar-toggler{
      position: absolute;
      right: 15px;
      background-image: none;
      border: 0px;
      padding: 0px;
      top: 1px;
      width: 40px;
      height: 36px;
      line-height: 1;
      .navbar-toggler-icon{
        background-image: none;
        &:after{
          font-family: 'Linearicons';
          content: "\e92b";
          position: absolute;
          right:0;
          font-size: 2em;
          top:0px;
          transition: all .3s;
          -webkit-transition: all .3s;
          color:#000;
          cursor: pointer;
        }
      }
    }
  }
  .navbar.navbar-expand-lg {
    .nav-link.btn{
      margin-left:0px;
    }
    .navbar-collapse{
      height: 230px;
      overflow-y: scroll;
    }
  }
  .hero-section{
    padding-top: 100px!important;
    text-align: center;
  }
}

.features {
  //background-image: url('../images/bg@2x.png');
  background-position-x: 50%;
  background-position-y: 100%;
  background-repeat: no-repeat;
}

.hero-section h2.h1 {
  font-size: 2.8em;
}

.bg-primary-dark {
  background-color: #828DAB;
}

.btn-primary:not(:disabled):not(.disabled).active {
  background-color: $primary-color;
  border-color: $primary-color;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Card {
  width: 250px;
  margin: 1rem;
  padding: 10px;
  background-color: #fefefe;
  box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
  text-align: center;
  border-radius: 3px;
  transition: all ease 300ms;
  cursor: pointer;
}

.Card-img {
  padding: 20px 10px;
  width: 150px;
}

.Card:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

/*.Section {
  padding: 10px;
  margin: 1rem;
}*/
.Section-buttons {
  margin-top: 4rem;
  display: flex;
  justify-content: space-around;
}

/*.Section-buttons button {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 30px;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}*/

/*.Section-buttons button i {
  color: #333;
  padding: 2px 6px 0 6px;
}*/

/*.Section-buttons .cta {*/
/*  background-color: #F8C94E;*/
/*}*/

.Section-slider-wrapper {
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
}

.Section-slider-wrapper .Section-sliderImg {
  font-size: 2em;
  padding: 0.25em;
  color: #5E7C88;
  margin-top: -10px;
}

.Section-slider {
  width: 75%;
  padding: 0;
  margin: 1rem;
}

.Section-slider .rc-slider-mark .rc-slider-mark-text:first-child {
  max-width: 60px;
}

.Wizard {
  /*max-width: 800px;*/
  margin: 0 auto;
}

.Wizard-section-gender-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

button {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 30px;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

/* Carousel */
.carousel {
  img,
  .slide img {
    width: inherit;
  }

  &.carousel-slider {
    padding-bottom: 50px;
  }
  .control-dots li.dot {
    background-color: #999;
    box-shadow: none;
    &.selected {
      background-color: $primary-color;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-31 11:52:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
