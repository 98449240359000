/*
Plant positon and animation
*/

.transparent {
  opacity: 0;
}

// Stem
.stem-position {
  transform: translate(-2%, -12%);
  opacity: 1;
}

.step0-stem-animation {
  opacity: 0;
}
.step1-stem-animation {
  -webkit-animation: scale-in-bottom1 2s both;
  animation: scale-in-bottom1 2s both;
}
.step2-stem-animation {
  -webkit-animation: scale-in-bottom2 2s both;
  animation: scale-in-bottom2 2s both;
}
.step3-stem-animation {
  -webkit-animation: scale-in-bottom3 2s both;
  animation: scale-in-bottom3 2s both;
}

// Top Capsule
.step7-top-capsule-position {
  transform: translate(52%,14%) rotate(-60deg) scale(1.2);
  opacity: 1;
}
.step8-top-capsule-position {
  transform: translate(52%,14%) rotate(-60deg) scale(1.2);
  opacity: 1;
}
// Top Pill
.step7-top-pill-position {
  transform: translate(47%, 12%) rotate(-50deg) scale(1.1);
  opacity: 1;
}
.step8-top-pill-position {
  transform: translate(47%, 12%) rotate(-50deg) scale(1.1);
  opacity: 1;
}
// Top Left Stem
.step6-top-left-stem-position {
  transform: translate(50%, 41%) rotate(-110deg) scale(0.25, 0.1);
  opacity: 1;
}
.step7-top-left-stem-position {
  transform: translate(50%, 41%) rotate(-110deg) scale(0.25, 0.1);
  opacity: 1;
}
.step8-top-left-stem-position {
  transform: translate(50%, 41%) rotate(-110deg) scale(0.25, 0.1);
  opacity: 1;
}
// Top Left Capsule1
.step7-top-left-capsule1-position {
  transform: translate(50%, 28%) rotate(120deg);
  opacity: 1;
}
.step8-top-left-capsule1-position {
  transform: translate(50%, 28%) rotate(120deg);
  opacity: 1;
}
// Top Left Capsule2
.step7-top-left-capsule2-position {
  transform: translate(50%, 33%) rotate(-90deg) scale(1.1);
  opacity: 1;
}
.step8-top-left-capsule2-position {
  transform: translate(50%, 33%) rotate(-90deg) scale(1.1);
  opacity: 1;
}
// Top Left Leaf
.step2-top-left-leaf-position {
  transform: translate(72.58%,8%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}
.step2-top-left-leaf-animation {
  -webkit-animation: step2-scale-up-leaf2 2s infinite;
          animation: step2-scale-up-leaf2 2s both;
}
@-webkit-keyframes step2-scale-up-leaf2 {
  0% {
    -webkit-transform: scaleX(0.1);
            transform: scaleX(0.1);
    -webkit-transform-origin: 53% 85%;
            transform-origin: 53% 85%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes step2-scale-up-leaf2 {
  0% {
    -webkit-transform: scaleX(0.1);
            transform: scaleX(0.1);
    -webkit-transform-origin: 53% 85%;
            transform-origin: 53% 85%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
.step3-top-left-leaf-position {
  opacity: 1;
  -webkit-animation: step3-move-up-leaf2 2s both;
  animation: step3-move-up-leaf2 2s both;
}
@-webkit-keyframes step3-move-up-leaf2 {
  0% {
    -webkit-transform: translate(72.7%,8%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
            transform: translate(72.7%,8%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  }
  100% {
    -webkit-transform: translate(71%,-54%) rotate(-10deg) rotateY(130deg) scale(0.9);
            transform: translate(71%,-54%) rotate(-10deg) rotateY(130deg) scale(0.9);
  }
}
@keyframes step3-move-up-leaf2 {
  0% {
    -webkit-transform: translate(72.7%,8%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
            transform: translate(72.7%,8%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  }
  100% {
    -webkit-transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
            transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  }
}
.step4-top-left-leaf-position {
  transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}
.step5-top-left-leaf-position {
  transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}
.step6-top-left-leaf-position {
  // transform: translate(71%,-54%) rotate(-10deg) rotateY(130deg) scale(0.9);
  transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}
.step7-top-left-leaf-position {
  transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}
.step8-top-left-leaf-position {
  transform: translate(76%,-30%) rotate(-10deg) rotateY(130deg) scale(0.9, 0.6);
  opacity: 1;
}

// Top Right Stem
.step5-top-right-stem-position {
  transform: translate(64%, 10%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
.step6-top-right-stem-position {
  transform: translate(64%, 10%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
.step7-top-right-stem-position {
  transform: translate(64%, 10%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
.step8-top-right-stem-position {
  transform: translate(64%, 10%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
// Top Right Capsule
.step7-top-right-capsule-position {
  transform: translate(70%,34%) rotate(170deg);
  opacity: 1;
}
.step8-top-right-capsule-position {
  transform: translate(70%,34%) rotate(170deg);
  opacity: 1;
}
// Top Right Pill
.step7-top-right-pill-position {
  transform: translate(62%, 35%) rotate(35deg);
  opacity: 1;
}
.step8-top-right-pill-position {
  transform: translate(62%, 35%) rotate(35deg);
  opacity: 1;
}
// Top Right Leaf
.step2-top-right-leaf-position {
  transform: translate(68.7%,60%) rotate(15deg) rotateY(180deg) ;
  opacity: 1;
}
.step2-top-right-leaf-animation {
  -webkit-animation: step2-scale-up-leaf3 2s both;
          animation: step2-scale-up-leaf3 2s both;
}
@-webkit-keyframes step2-scale-up-leaf3 {
  0% {
    -webkit-transform: scaleX(0.1);
            transform: scaleX(0.1);
  -webkit-transform-origin: 14% 7%;
          transform-origin: 14% 7%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 14% 7%;
            transform-origin: 14% 7%;
  }
}
@keyframes step2-scale-up-leaf3 {
  0% {
    -webkit-transform: scaleX(0.1);
            transform: scaleX(0.1);
  -webkit-transform-origin: 14% 7%;
          transform-origin: 14% 7%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 14% 7%;
            transform-origin: 14% 7%;
  }
}
.step3-top-right-leaf-position {
  opacity: 1;
  -webkit-animation: step3-move-up-leaf3 2s both;
  animation: step3-move-up-leaf3 2s both;
}
@-webkit-keyframes step3-move-up-leaf3 {
  0% {
     -webkit-transform: translate(56.5%,58%) rotate(15deg) rotateY(180deg) scale(1);
             transform: translate(56.5%,58%) rotate(15deg) rotateY(180deg) scale(1);
    -webkit-transform-origin: 5% 8%;
            transform-origin: 5% 8%;
  }
  100% {
    -webkit-transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  }
}
@keyframes step3-move-up-leaf3 {
0% {
     -webkit-transform: translate(56.5%,58%) rotate(15deg) rotateY(180deg) scale(1);
             transform: translate(56.5%,58%) rotate(15deg) rotateY(180deg) scale(1);
    -webkit-transform-origin: 5% 8%;
            transform-origin: 5% 8%;
  }
  100% {
    -webkit-transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  }
}
.step4-top-right-leaf-position {
  transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  opacity: 1;
}
.step5-top-right-leaf-position {
  transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  opacity: 1;
}
.step6-top-right-leaf-position {
  transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  opacity: 1;
}
.step7-top-right-leaf-position {
  transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  opacity: 1;
}
.step8-top-right-leaf-position {
  transform: translate(73%,22%) rotate(15deg) rotateY(180deg) scale(1);
  opacity: 1;
}

// Middle Left Stem
.step5-middle-left-stem-position {
  transform: translate(46%, 66%) rotate(-115deg) scale(0.3, 0.15);
  opacity: 1;
}
.step6-middle-left-stem-position {
  transform: translate(46%, 66%) rotate(-115deg) scale(0.3, 0.15);
  opacity: 1;
}
.step7-middle-left-stem-position {
  transform: translate(46%, 66%) rotate(-115deg) scale(0.3, 0.15);
  opacity: 1;
}
.step8-middle-left-stem-position {
  transform: translate(46%, 66%) rotate(-115deg) scale(0.3, 0.15);
  opacity: 1;
}
// Middle Left Capsule
.step7-middle-left-capsule-position {
  transform: translate(40%, 56%) rotate(-70deg) scale(1.23);
  opacity: 1;
}
.step8-middle-left-capsule-position {
  transform: translate(40%, 56%) rotate(-70deg) scale(1.23);
  opacity: 1;
}
// Middle Left Pill
.step7-middle-left-pill-position {
  transform: translate(45%, 49%) rotate(5deg) scale(1.1);
  opacity: 1;
}
.step8-middle-left-pill-position {
  transform: translate(45%, 49%) rotate(5deg) scale(1.1);
  opacity: 1;
}
// Middle Left Leaf
.step3-middle-left-leaf-position {
  opacity: 1;
}
.step3-middle-left-leaf-animation {
  opacity: 1;
  -webkit-animation: step3-move-and-scale-up-leaf3 2s both;
  animation: step3-move-and-scale-up-leaf3 2s both;

}
.step4-middle-left-leaf-position {
  transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  opacity: 1;
}
@-webkit-keyframes step3-move-and-scale-up-leaf3 {
  0% {
    -webkit-transform: translate(48%,75%) rotate(-30deg) scale(0.1);
            transform: translate(48%,75%) rotate(-30deg) scale(0.1);
  }
  100% {
    -webkit-transform: translate(28%,40%) rotate(-30deg) scale(1.6);
            transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  }
}
@keyframes step3-move-and-scale-up-leaf3 {
  0% {
    -webkit-transform: translate(48%,75%) rotate(-30deg) scale(0.1);
            transform: translate(48%,75%) rotate(-30deg) scale(0.1);
  }
  100% {
    -webkit-transform: translate(28%,40%) rotate(-30deg) scale(1.6);
            transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  }
}
.step5-middle-left-leaf-position {
  transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  opacity: 1;
}
.step6-middle-left-leaf-position {
  transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  opacity: 1;
}
.step7-middle-left-leaf-position {
  transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  opacity: 1;
}
.step8-middle-left-leaf-position {
  transform: translate(28%,40%) rotate(-30deg) scale(1.6);
  opacity: 1;
}

// Middle Right Stem
.step6-middle-right-stem-position {
  transform: translate(62%, 38%) rotate(80deg) scale(0.4, 0.1);
  opacity: 1;
}
.step7-middle-right-stem-position {
  transform: translate(62%, 38%) rotate(80deg) scale(0.4, 0.1);
  opacity: 1;
}
.step8-middle-right-stem-position {
  transform: translate(62%, 38%) rotate(80deg) scale(0.4, 0.1);
  opacity: 1;
}
// Middle Right Capsule1
.step7-middle-right-capsule1-position {
  transform: translate(65%,55%) rotate(130deg);
  opacity: 1;
}
.step8-middle-right-capsule1-position {
  transform: translate(65%,55%) rotate(130deg);
  opacity: 1;
}
// Middle Right Capsule2
.step7-middle-right-capsule2-position {
  transform: translate(62%,58%) rotate(70deg);
  opacity: 1;
}
.step8-middle-right-capsule2-position {
  transform: translate(62%,58%) rotate(70deg);
  opacity: 1;
}
// Middle Right Pill
.step7-middle-right-pill-position {
  transform: translate(63%, 56%) rotate(5deg) scale(1.3);
  opacity: 1;
}
.step8-middle-right-pill-position {
  transform: translate(63%, 56%) rotate(5deg) scale(1.3);
  opacity: 1;
}
.step8-middle-right-pill-animation {
  -webkit-animation: jello-horizontal 0.8s both;
  animation: jello-horizontal 0.8s both;
}
// Middle Right Leaf
.step4-middle-right-leaf-position {
  transform: translate(131%,-23%) rotate(15deg) rotateY(180deg);
  opacity: 1;
}
.step5-middle-right-leaf-position {
  transform: translate(131%,-23%) rotate(15deg) rotateY(180deg);
  opacity: 1;
}
.step6-middle-right-leaf-position {
  transform: translate(131%,-23%) rotate(15deg) rotateY(180deg);
  opacity: 1;
}
.step7-middle-right-leaf-position {
  transform: translate(131%,-23%) rotate(15deg) rotateY(180deg);
  opacity: 1;
}
.step8-middle-right-leaf-position {
  transform: translate(131%,-23%) rotate(15deg) rotateY(180deg);
  opacity: 1;
}

// Bottem Stem
.step6-bottom-stem-position {
  transform: translate(56%, 51%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
.step7-bottom-stem-position {
  transform: translate(56%, 51%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
.step8-bottom-stem-position {
  transform: translate(56%, 51%) rotate(80deg) scale(0.5, 0.1);
  opacity: 1;
}
// Bottom Pill1
.step7-bottom-pill1-position {
  transform: translate(55%, 73%) rotate(-25deg) scale(1.4);
  opacity: 1;
}
.step8-bottom-pill1-position {
  transform: translate(55%, 73%) rotate(-25deg) scale(1.4);
  opacity: 1;
}
// Bottom Pill2
.step7-bottom-pill2-position {
  transform: translate(55%, 76%) rotate(20deg);
  opacity: 1;
}
.step8-bottom-pill2-position {
  transform: translate(55%, 76%) rotate(20deg);
  opacity: 1;
}

// Bottom Left Leaf
.step4-bottom-left-leaf-position {
  transform: translate(-10%,-32%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step5-bottom-left-leaf-position {
  transform: translate(-10%,-32%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step6-bottom-left-leaf-position {
  transform: translate(-10%,-32%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step7-bottom-left-leaf-position {
  transform: translate(-10%,-32%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step8-bottom-left-leaf-position {
  transform: translate(-10%,-32%) rotate(1deg) scale(1.2);
  opacity: 1;
}

// Bottom Right Leaf
.step5-bottom-right-leaf-position {
  transform: translate(-11%,-28%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step6-bottom-right-leaf-position {
  transform: translate(-11%,-28%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step7-bottom-right-leaf-position {
  transform: translate(-11%,-28%) rotate(1deg) scale(1.2);
  opacity: 1;
}
.step8-bottom-right-leaf-position {
  transform: translate(-11%,-28%) rotate(1deg) scale(1.2);
  opacity: 1;
}

// Leaf grow animation
.step4-leaf1-grow-animation {
  -webkit-animation: scale-up-leaf1 1.5s both;
  animation: scale-up-leaf1 1.5s both;
}
.step5-leaf2-grow-animation {
  -webkit-animation: scale-up-leaf1 1.5s both;
  animation: scale-up-leaf1 1.5s both;
}
.step5-leaf3-grow-animation {
  -webkit-animation: scale-up-leaf3 1.5s infinite;
  animation: scale-up-leaf3 1.5s infinite;
}

// Fruit stem grow animation
.step5-grow-animation-med {
  -webkit-animation: scale-up 1s both;
  animation: scale-up 1s both;
}

// Fruit stem grow animation
.step6-grow-animation-fast {
  -webkit-animation: scale-up 0.5s both;
  animation: scale-up 0.5s both;
}
.step6-grow-animation-slow {
  -webkit-animation: scale-up 1.5s both;
  animation: scale-up 1.5s both;
}

// Pill/Capsule grow animation
.step7-animation-fast {
  -webkit-animation: scale-up-tl 0.4s both;
  animation: scale-up-tl 0.4s both;
}
.step7-animation-med {
  -webkit-animation: scale-up-tl 0.6s both;
  animation: scale-up-tl 0.6s both;
}
.step7-animation-slow {
  -webkit-animation: scale-up-tl 0.8s both;
  animation: scale-up-tl 0.8s both;
}
// Pill/Capsule grow animation reversed
.step7-animation-rev-fast {
  -webkit-animation: scale-up-br 0.4s both;
  animation: scale-up-br 0.4s both;
}

// Pill/Capsule shake animation
.step8-animation-fast {
  -webkit-animation: jello-horizontal 0.4s both;
  animation: jello-horizontal 0.4s both;
}
.step8-animation-med {
  -webkit-animation: jello-horizontal 0.6s both;
  animation: jello-horizontal 0.6s both;
}
.step8-animation-slow  {
  -webkit-animation: jello-horizontal 0.8s both;
  animation: jello-horizontal 0.8s both;
}
.step8-animation-rev-fast {
  -webkit-animation: jello-horizontal 0.4s both;
  animation: jello-horizontal 0.4s both;
}
.step8-animation-rev-med {
  -webkit-animation: jello-horizontal 0.6s both;
  animation: jello-horizontal 0.6s both;
}
.step8-animation-rev-slow  {
  -webkit-animation: jello-horizontal 0.8s both;
  animation: jello-horizontal 0.8s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-22 14:39:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
@-webkit-keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
/**
 * ----------------------------------------
 * animation scale-up
 * ----------------------------------------
 */
@-webkit-keyframes scale-up {
  0% {
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes scale-up {
  0% {
    -webkit-transform: scaleY(0.1);
            transform: scaleY(0.1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
/**
 * ----------------------------------------
 * animation scale-up-leaf1
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-leaf1 {
  0% {
    -webkit-transform: scale(0.1, 0.5);
            transform: scale(0.1, 0.5);
    -webkit-transform-origin: 50% 89%;
            transform-origin: 50% 89%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes scale-up-leaf1 {
  0% {
    -webkit-transform: scale(0.1, 0.5);
            transform: scale(0.1, 0.5);
    -webkit-transform-origin: 50% 89%;
            transform-origin: 50% 89%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
/**
 * ----------------------------------------
 * animation scale-up-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation scale-up-tl-reversed
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 2% 5%;
            transform-origin: 2% 5%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 2% 5%;
            transform-origin: 2% 5%;
  }
}
@keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 2% 5%;
            transform-origin: 2% 5%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 2% 5%;
            transform-origin: 2% 5%;
  }
}
/**
 * ----------------------------------------
 * animation scale-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-bottom1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes scale-in-bottom2 {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom2 {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes scale-in-bottom3 {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom3 {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
