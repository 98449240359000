$title-font:"Robotto", sans-serif;
$base-font:"Lato", sans-serif;
$primary-color:#FE7820;
$danger-color:#c9302c;
$success-color:#5cb85c;
$warning-color:#F58B44;
$default-color:#f7f7f7;
$dark-color:#4B5161;
$info-color:#52B6DE;

